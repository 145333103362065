export const sortArrayObjectByKey = (array, key) => {
  return array.sort(function(a, b) {
    let x = a[key]; let y = b[key];
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}


export const checkModalTabIndex = (array) => {
  let startIdx = 2000;
  let endIdx = 2999;
  return array.filter((item) => {
    return item.tabIndex > startIdx && item.tabIndex < endIdx
  })
}

export const childToParent = (pArr, cArr) => {
  for(let i=0; i<cArr.length; i++) {
    let checkIdx = pArr.findIndex(x => x.id === cArr[i].pid)
    if(checkIdx !== -1) {
      if(typeof pArr[checkIdx].children === "undefined") pArr[checkIdx].children = [];
      pArr[checkIdx].children.push(cArr[i])
    }
  }
  return pArr
}

export const listToTree = (array) => {
  let map = {}, node, roots = [], i;
  
  for (i = 0; i < array.length; i += 1) {
    map[array[i].id] = i;
    array[i].children = [];
  }

  for (i = 0; i < array.length; i += 1) {
    node = array[i];
    if (array.find(x => x.id === node.pid) && node.pid && node.pid !== "0") {
      console.log(array.find(x => x.id === node.pid))
      array[map[node.pid]].children.push(node);
    } 
    else {
      roots.push(node);
    }
  }
  return roots;
}

// export const listToTree = (arr, parent = 0) => {
//   console.log(arr)
//   const retArray =  arr.filter(item => item.pId == parent) .map(child => ({ ...child, children: listToTree(arr, child.id) })); 
//   console.log(retArray)
// }

export const arrayToObjectKey = (arr, key) => {
  let retArray = [];
  for(let i=0; i<arr.length; i++) {
    let retObj = {}
    retObj[key] = arr[i]
    retArray.push(retObj)
  }
  return retArray;
}


export const alarmActiveClass = (deviceGuid, alarmType) => {
  switch (alarmType) {
    case 'alert':
      document.getElementById("map_" + deviceGuid).className += ' sm-alarm-alert-active';
      return;
    case 'check':
      document.getElementById("map_" + deviceGuid).className += ' sm-alarm-check-active';
      return;
    case 'unalert':
      document.getElementById("map_" + deviceGuid).classList.remove('sm-alarm-alert-active');
      return;
    case 'uncheck':
      document.getElementById("map_" + deviceGuid).classList.remove('sm-alarm-check-active');
      return;
    default:
      return;
  }
}

export const groupByArray = (arr, idx) => {
  return arr.reduce(function (acc, obj) {
    let key = obj[idx]
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, {})
}